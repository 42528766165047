<template>
  <FullScreenDialog
    v-model="promoDialog"
    :title="promoTitle"
    :routeFather="pathToGoBack"
    :routeFatherName="pathName"
    :otherGoBackParams="{ params: { filters: this.filters } }"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in promoTabs"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in promoTabs" :key="tabItem.key">
          <v-card flat>
            <v-card-text class="pa-0">
              <component 
                :is="tabItem.component"
                :type="tabItem.props.type"
              >
              </component>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <StandardDialog
        v-model="promoEditErrorDialog"
        :dialog-height="null"
        dialog-width="400px"
        title="Promozione ancora in uso"
        @close="promoEditErrorDialog = false"
      >
        <v-alert 
          border="left"
          class="py-2 my-3"
          color="error"
        >
          Non è possibile modificare la promo, è ancora attiva su alcuni clienti.
        </v-alert>
    </StandardDialog>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="close"
        >Annulla</v-btn>
        <v-btn
          text
          color="default"
          :disabled="!formValid || isLicenseBlocked"
          @click="submitForm"
        >Salva</v-btn>
      </div>
    </template>

    <template v-slot:header-actions>
      <div>
        <StandardMenu
          close-on-content-click
        >
          <template v-slot:activator="{on, attrs}">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-printer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Stampa</v-list-item-content>
              </v-list-item>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon color="error">mdi-backspace-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="error--text">Resetta</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </StandardMenu>
      </div>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from '@/components/common/StandardMenu.vue';
import GeneralForm from "@/components/areas/payments/promos/form/GeneralFormTab.vue"
import StandardDialog from "@/components/common/StandardDialog.vue";
import promosService from '@/services/promos/promos.service.js'
import promoForm from '@/services/promos/promo.form.js'
import { cloneDeep } from 'lodash'

export default {
  name: "PromosEditForm",
  components: {
    FullScreenDialog,
    StandardMenu,
    GeneralForm,
    StandardDialog
  },
  data: function () {
    return {
      id: undefined,
      tab: null,
      formValid: false,
      loadingPromo: false,
      promoDialog: this.openDialog,
      promoEditErrorDialog: false,
      promoTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm",
          props: {
            type: 'edit'
          }
        }
      ],
      promo: undefined,
      originalUrl: window.location.pathname,
      isLicenseBlocked: undefined
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    this.id = this.$route.params.id
    if(this.id) {
      this.fetchPromo()
    }

    let self = this
    promoForm.on('update', function(data) {
      self.promo = cloneDeep(data.promo)
    })

    self.valid = promoForm.valid
    promoForm.on('valid-change', function(valid) {
      self.formValid = valid
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/marketing",
    },
    pathName: {
      type: String,
    },
    tabName: {
      type: String,
      default: "general",
    },
    filters: {
      type: Array,
    },
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.promoTabs.length; i++) {
        if(this.promoTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    async submitForm() {
      let promoCustomerslist = await promosService.listPromoCustomerByPromoId(this.promo)

      if(promoCustomerslist.length > 0) {
        for(let i = 0; i < promoCustomerslist.length; i++) {
          if(promoCustomerslist[i].usageProgress == 'beginning') {
            this.promoEditErrorDialog = true;
            return
          }
        }
      }

      promosService.update(this.promo).then((result) => {
        this.close()
      })
    },
    close() {
      this.itemDialog = false;
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack})
    },
    fetchPromo() {
      this.loadingPromo = true
      promoForm.resetPromo()
      promosService.get(this.id).then((promo) => {
        this.loadingPromo = false
        promoForm.updateObject(promo)
      })
    }
  },
  computed: {
    promoTitle() {
      return !!this.promo ? this.promo.name : 'Modifica promozioni'
    }
  },
  watch: {
    openDialog(newVal) {
      this.promoDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.promoTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>